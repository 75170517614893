import styles from './Features.module.scss';
import Slider from '#components/ui/Slider';
import FeatureCard from '#components/ui/FeatureCard';
import { initI18Next } from '#utils/I18nextInit';
import i18next from 'i18next';

type Props = {
  features: {
    title: string;
    description: string;
    type: string;
  }[];
};

initI18Next(i18next, i18next.language);
const lang = i18next.language;

const renderSlide = (slide: { title: string; description: string; type: string }) => (
  <div className={`${styles.featuresWrapper} ${styles.mobile}`}>
    <FeatureCard feature={slide} lang={lang} />
  </div>
);

const FeaturesMobile = ({ features }: Props) => {
  return (
    <Slider
      className={styles.featuresSlider}
      slides={features}
      renderSlide={renderSlide}
      slidesPerViewMobile="auto"
      sliderType="features"
    />
  );
};

export default FeaturesMobile;
